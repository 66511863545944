import './App.css';
import salemImage from './img/salem.jpeg';
import etsy from './img/etsy.png';

function App() {
  return (
    <div className="App">
      <div className='flexColumn' >
        <span className="flexRow">
          <h1>ALLY MCH</h1>
          <a
            style={{marginTop: '5px'}}
            href='https://www.etsy.com/shop/RavensRoostTrinkets'
            target='blank' >
            <img height='31px' src={etsy} />
          </a>
          <h1>RMAN</h1>

        </span>
        <img 
        style={
          {
            height: '500px',
            borderRadius: '50%',
            marginBottom: '100px'
          }
        }
        src={salemImage} />
      </div>
    </div>
  );
}

export default App;
